// import { users, teams, fixtures } from "../fakeApi";

export const initialState = {
  // users,
  // teams,
  // players,
  // fixtures,
  screenMode: 0,
  currentUser: {},
};
