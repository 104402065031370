import AddChildForm from "../Forms/AddChildForm";

const AddChild = () => {
  return (
    <>
      <div className="container">
        <AddChildForm />
      </div>
    </>
  );
};

export default AddChild;
